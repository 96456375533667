// export const BACKEND_DOMAIN = 'http://localhost:5001'
// export const BACKEND_DOMAIN = 'https://ozdesign-middleware.undev.com.au'
export const BACKEND_DOMAIN = ''
const BASE_URL = `${BACKEND_DOMAIN}/v1`
export const POST_LOGIN = `${BASE_URL}/auth/login`
export const POST_REGISTER = `${BASE_URL}/auth/register`
export const POST_FORGOT_PASSWORD = `${BASE_URL}/auth/forgot-password`
export const POST_FORGOT_PASSWORD_VERIFY = `${BASE_URL}/auth/forgot-password/verify`
export const POST_RESET_PASSWORD = `${BASE_URL}/auth/reset-password`

export const POST_SYNC_CARTS = `${BASE_URL}/sync/carts`
export const POST_SYNC_PROMOTIONS = `${BASE_URL}/sync/promotions`
export const GET_LOGS = `${BASE_URL}/sync/logs`
export const POST_SYNC_STORES = `${BASE_URL}/sync/stores`
export const POST_SYNC_CUSTOMERS = `${BASE_URL}/sync/customers`
export const POST_SYNC_ORDERS = `${BASE_URL}/sync/orders`
export const POST_SYNC_QUOTES = `${BASE_URL}/sync/quotes`

export const SAVE_ACCESS_TOKEN = `${BASE_URL}/accessToken/save`
export const SAVE_CODE = `${BASE_URL}/accessToken/saveCode`
export const GET_CODE = `${BASE_URL}/accessToken/get`

// sync type
export const SYNC_CART_TYPE = 'SYNC_CART_TYPE'
export const SYNC_PRODUCT_TYPE = 'SYNC_PRODUCT_TYPE'
export const SYNC_PROMOTION_TYPE = 'SYNC_PROMOTION_TYPE'
export const SYNC_STORE_TYPE = 'SYNC_STORE_TYPE'

// url feed
export const URL_FEED_CART = `${BACKEND_DOMAIN}/v1/sync/carts/all.csv`
export const URL_FEED_PRODUCT = `${BACKEND_DOMAIN}/v1/sync/products/all.csv`
export const URL_FEED_PROMOTION = `${BACKEND_DOMAIN}/v1/sync/promotions/all.csv`
export const URL_FEED_STORE = `${BACKEND_DOMAIN}/v1/sync/stores/all.csv`
export const URL_FEED_CONTACT = `${BACKEND_DOMAIN}/v1/sync/customers/all.csv`
export const URL_FEED_ORDER = `${BACKEND_DOMAIN}/v1/sync/orders/all.csv`
export const URL_FEED_QUOTE = `${BACKEND_DOMAIN}/v1/sync/quotes/all.csv`

export const URL_ORDERHEADER_CSV = `${BACKEND_DOMAIN}/v1/sync/orders/all.csv?filename=orders`
export const URL_ORDERLINEITEMS_CSV = `${BACKEND_DOMAIN}/v1/sync/orders/all.csv?filename=orderlineitems`

// magento
export const GET_MAGENTO_BASE_URL = `${BACKEND_DOMAIN}/v1/sync/magento/base-url`

// cloudinary image
export const BASE_CLOUDINARY_IMAGE_URL = `${BACKEND_DOMAIN}/v1/image/cloudinary`
export const CLOUDINARY_IMAGE_SYNC = `${BASE_CLOUDINARY_IMAGE_URL}/sync`
